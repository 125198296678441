import mqtt from "mqtt";
import Vue from "vue";
export const CODE_BOOK = {
  // VIDEO 视频类
  VIDEO_REQUEST: "A01", // 发起视频请求
  VIDEO_RESPONSE_ACCEPTED: "A02", // 接收视频请求
  VIDEO_RESPONSE_REJECTED: "A03", // 拒绝视频请求
  VIDEO_DISCONNECTED: "A04", // 挂断视频通话
};
class MqttClient {
  // static client = mqtt.connect("ws://47.116.34.50:9001");
  static client = mqtt.connect("wss://mqtt.szdcecs.com");
  static handlers = [];
  constructor() {
    console.log("create mqtt connection ...");
    this.client = MqttClient.client;
    this.handlers = MqttClient.handlers;
    this.client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      this.handlers.forEach((fn) => {
        fn(topic, JSON.parse(message.toString()));
      });
    });
    console.log("mqtt connected");
  }
  /**
   * 订阅主题
   * @param {string} topic
   */
  subscribe(topic) {
    this.client.subscribe(topic, (err) => {
      if (err) {
        console.log(`subscribe ${topic} failed`);
      }
    });
  }
  /**
   * 发布消息
   * @param {string} topic 主题
   * @param {object} payload 消息体
   */
  publish(topic, payload) {
    // console.log('proxy publish')
    this.client.publish(topic, JSON.stringify(payload));
  }

  addEventListener(callback) {
    this.handlers.push(callback);
  }
  disconnect({ topic, fromUserId, toUserId }) {
    const msg = {
      header: {
        type: "signal",
        sender: fromUserId,
        receiver: toUserId,
      },
      body: {
        code: CODE_BOOK.VIDEO_DISCONNECTED, // 发起视频请求
      },
    };
    console.log(msg)
    this.publish(topic, msg);
  }
}

Vue.prototype.$mqtt = new MqttClient();
